import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link as GLink } from 'gatsby'
import {
  Container,
  Box,
  Flex,
  css,
  MenuButton,
  MenuList,
  MenuItem
} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block02'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import ServicesDetails from '@solid-ui-blocks/Faq/Block02'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import CompaniesPhoto from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import JoinCompanies from '@solid-ui-blocks/Content/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import Tabs from '@solid-ui-components/Tabs'
import './customStyleD.css'

import $ from 'jquery'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import { FaQuoteRight } from 'react-icons/fa'
import { FaStar } from 'react-icons/fa'
import { FaRegStar } from 'react-icons/fa'
import { FaGooglePlay } from 'react-icons/fa'
import { FaAppStore } from 'react-icons/fa'

import shutterstock_4 from './assets/shutterstock_4.jpg'
import shutterstock_5 from './assets/shutterstock_5.jpg'
import shutterstock_7 from './assets/shutterstock_7.jpg'
import userDummy1 from './assets/userDummy1.jpg'
import userDummy2 from './assets/userDummy2.jpg'
import userDummy3 from './assets/userDummy3.jpg'
import userDummy4 from './assets/userDummy4.jpg'
import appStoreBadge from './assets/appStoreBadge.png'
import playStoreBadge from './assets/playStoreBadge.png'

import Testimonials from '../Testimonials'

import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureTabTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import FeatureTabThree from '@solid-ui-blocks/FeaturesWithPhoto/Block07'

const ServiceDPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  const settings = {
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,
    arrows: false,
    speed: 2000
  }

  const MyHTMLComponent = () => {
    return (
      <div className='service_spacer'>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sec1'>
              <h1>Floki Wholesale App (B2B)</h1>
              <p>
                Streamline your ordering process and grow your wholesale
                business with our user-friendly wholesale e-commerce
                app,designed to make online shopping easier than ever!
              </p>
            </div>
          </div>
        </div>
        <div class='discrptn'>
          <div class='mycontainer'>
            <p>
              Introducing the Floki Wholesale App, a white-label e-commerce
              solution designed exclusively for wholesalers. With Floki
              user-friendly and customizable platform, you can streamline your
              ordering process and experience business growth. Our secure and
              reliable wholesale e-commerce app allows your customers to easily
              place orders and track deliveries, while you benefit from
              increased efficiency and customer satisfaction. Our cutting-edge
              B2B e-commerce platform not only ensures secure and reliable
              transactions but also enhances customer service by enabling easy
              order placement and seamless tracking of deliveries. Join the
              ranks of successful wholesalers who have already transformed their
              operations with our wholesale e-commerce platform. Don't miss out
              on the opportunity to elevate your B2B e-commerce application to
              new heights. Try the Floki wholesale e-commerce app today!
            </p>
          </div>
        </div>
        <div class='product1'>
          <div class='mycontainer'>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_4} alt='product_img' />
                  {/* <img src="images/shutterstock_4.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Key Features :</h4>
                  <ul class='liststyle'>
                    <li>
                      Embrace the convenience of Floki wholesale e-commerce app,
                      a <b> user-friendly solution</b> for a seamless B2B
                      e-commerce platform.
                    </li>
                    <li>
                      Optimize your business with a
                      <b> customizable wholesale e-commerce platform</b>,
                      tailored for B2B e-commerce applications in wholesale
                      distribution.
                    </li>
                    <li>
                      Cater to diverse customer groups and boost profitability
                      with <b>multiple pricing options</b> on Floki wholesale
                      e-commerce platform.
                    </li>
                    <li>
                      Drive sales and engagement through
                      <b> personalized promotions</b>
                      on Floki B2B ecommerce app, enhancing the customer
                      experience.
                    </li>
                    <li>
                      Keep customers informed with
                      <b> order tracking and delivery notifications</b> on Floki
                      wholesale e-commerce platform, ensuring a smooth process.
                    </li>
                    <li>
                      Effortlessly manage your operations by <b>integrating </b>
                      major payment gateways, accounting software, and CRMs into
                      Floki B2B e-commerce application.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <h4>Benefits :</h4>
                  <ul class='liststyle'>
                    <li>
                      <strong>Branding and customization:</strong> Personalize
                      the wholesale e-commerce app or B2B e-commerce platform
                      with your branding to establish a unique identity.
                    </li>
                    <li>
                      <strong>Streamlined operations:</strong> Optimize
                      inventory management, order tracking, and logistics using
                      our wholesale ecommerce ecommerce app.
                    </li>
                    <li>
                      <strong>Customer engagement:</strong>
                      Drive stronger customer engagement with a user-friendly
                      ordering experience through personalized catalogs and
                      quick order placement in our wholesale e-commerce
                      platform.
                    </li>
                    <li>
                      <strong>Expanded market reach:</strong> Showcase your
                      products to a wider customer base in diverse regions
                      through our B2B ecommerce app, tapping into new market
                      opportunities.
                    </li>
                    <li>
                      <strong>Analytics and insights:</strong>Gain valuable
                      data-driven insights into sales trends and customer
                      behavior using our wholesale e-commerce app, enabling
                      informed decision-making.
                    </li>
                    <li>
                      <strong>Scalability and growth:</strong>Accommodate
                      business growth effortlessly with our scalable B2B
                      e-commerce application, handling increased product
                      catalogs, customer volume, and transactions.
                    </li>
                  </ul>
                </div>
                <div class='colm6'>
                  <img src={shutterstock_5} alt='product_img' />
                  {/* <img src="images/shutterstock_5.jpg" alt="product_img"> */}
                </div>
              </div>
            </div>
            <div class='sect'>
              <div class='row'>
                <div class='colm6'>
                  <img src={shutterstock_7} alt='product_img' />
                  {/* <img src="images/shutterstock_7.jpg" alt="product_img"> */}
                </div>
                <div class='colm6'>
                  <h4>Use Cases :</h4>
                  <ul class='liststyle'>
                    <li>
                      A wholesaler who wants to streamline their ordering
                      process and reduce errors.
                    </li>
                    <li>
                      A distributor who wants to expand their customer base and
                      increase their revenue.
                    </li>
                    <li>
                      A business owner who wants to offer an easy and convenient
                      ordering experience to their customers.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Helmet>
        <title>
          Wholesale eCommerce App | B2B eCommerce Platform | Floki Solutions
        </title>
        <meta
          name='google-site-verification'
          content='jvulVjeMbr8Qb07aPAbFxWgBuwOl_Q2A9EvJoemO1co'
        />
        <meta
          name='description'
          content='Need a wholesale ecommerce app or b2b ecommerce platform? Our ecommerce solutions for wholesale distribution make it easy to manage your operations and grow your business.'
        />
        <meta
          name='keywords'
          content='wholesale ecommerce app, wholesale ecommerce app, b2b ecommerce platform, wholesale ecommerce platform, b2b ecommerce application, ecommerce for wholesale distribution'
        />

        <meta
          property='og:title'
          content='Wholesale eCommerce App | B2B eCommerce Platform | Floki Solutions'
        />
        <meta
          property='og:description'
          content='Need a wholesale ecommerce app or b2b ecommerce platform? Our ecommerce solutions for wholesale distribution make it easy to manage your operations and grow your business.'
        />

        <meta
          property='og:url'
          content='https://flokisolutionsmain.gatsbyjs.io/floki-wholesale-app-b2b/'
        />
        <meta property='og:type' content='website' />
      </Helmet>

      {/* Modals */}
      <ModalWithTabs content={content['authentication']} reverse />
      <ModalWithTabs content={content['contact']} />
      <ModalSimple content={content['advertisement']} />
      {/* Blocks */}

      <Header content={content['header']} />

      <MyHTMLComponent></MyHTMLComponent>

      <Tabs space={4} variant='pill'>
        <FeatureTabOne content={content['feature-tab-one']} />
        <FeatureTabTwo content={content['feature-tab-two']} reverse />
        <FeatureTabThree content={content['feature-tab-three']} />
      </Tabs>
      <Testimonials />

      {/* <Divider space='5' /> */}
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query ServiceDPageContent {
    allBlockContent(
      filter: { page: { in: ["site/floki-wholesale-app-b2b", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ServiceDPage
